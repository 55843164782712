<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <date-range-picker
          ref="picker"
          opens="center"
          :autoApply="true"
          v-model="dateRange"
          :linkedCalendars="false"
          @update="filters()"
          class="mt-2 elevation-2"
        >
          <template v-slot:input="picker" style="min-width: 350px">
            {{ picker.startDate | date }}
            {{ picker.startDate ? "-->" : $t("select_date") }}
            {{ picker.endDate | date }}
          </template>
        </date-range-picker>
        <v-btn
          v-if="dateRange.startDate"
          icon
          small
          @click="filters('clear_date')"
          class="ms-2"
        >
          <v-icon>clear</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead style="background-color: rgba(0, 0, 0, 0.1)">
              <tr>
                <th>{{ $t("point_sales") }}</th>
                <th>{{ $t("number_invoices") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pos, i) in posList" :key="i">
                <td>{{ pos.point_sales }}</td>
                <td>{{ pos.count }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { pos } from "@/api/URLs";
export default {
  name: "Pos",
  components: {
    DateRangePicker,
  },
  data: () => ({
    dateRange: { startDate: null, endDate: null },
    filterVal: {},
    posList: [],
  }),
  created() {
    this.filters();
  },
  methods: {
    filters(type) {
      if (type === "clear_date" && this.dateRange.startDate == null) return;

      let self = this;
      if (type === "clear_date") {
        self.dateRange.startDate = null;
        self.dateRange.endDate = null;
        self.filterVal.from_date = null;
        self.filterVal.to_date = null;
      }

      if (self.dateRange.startDate != null) {
        self.filterVal.from_date = self.$options.filters.date(
          self.dateRange.startDate
        );
        self.filterVal.to_date = self.$options.filters.date(
          self.dateRange.endDate
        );
      }
      this.$http({
        method: "post",
        url: pos,
        data: self.filterVal,
      }).then(function (response) {
        self.posList = response.data.data;
      });
    },
  },
};
</script>

<style scoped></style>
